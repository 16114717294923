export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/chat": [6],
		"/login": [7],
		"/login/2fa": [8],
		"/pbxcommander/health/[pbx_id]": [9],
		"/pbxcommander/terminal/[pbx_id]": [10],
		"/portal": [11,[2]],
		"/portal/administration/groups": [12,[2]],
		"/portal/administration/history": [13,[2]],
		"/portal/administration/users": [14,[2]],
		"/portal/grt/jobs": [15,[2]],
		"/portal/grt/test": [16,[2]],
		"/portal/hr/employees": [17,[2,3]],
		"/portal/hr/profile": [18,[2,3]],
		"/portal/hr/timesheets": [19,[2,3]],
		"/portal/logistics/audit": [20,[2,4]],
		"/portal/logistics/stock": [21,[2,4]],
		"/portal/pbxcommander": [22,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';